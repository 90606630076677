<template>
  <div class="form-student-class">
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item label="Level" class="hsn-input-required">
          <a-select
            size="large"
            class="w-100"
            :value="dataStudentClass.id_level"
            @change="(e) => handleChange(e, 'id_level')"
          >
            <a-select-option
              v-for="item in levelData"
              :key="item.key"
              :value="item.id"
            >
              {{ item.nama }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Symbol" class="hsn-input-required">
          <a-input
            size="large"
            class="w-100"
            :value="dataStudentClass.simbol"
            @change="(e) => handleChange(e.target.value, 'simbol')"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Study Program">
          <a-select
            size="large"
            class="w-100"
            :value="dataStudentClass.id_program"
            @change="(e) => handleChange(e, 'id_program')"
          >
            <a-select-option value="">No Program</a-select-option>
            <a-select-option
              v-for="item in programData"
              :key="item.key"
              :value="item.id"
            >
              {{ item.nama }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col v-if="status.isEdit" :span="24">
        <a-form-item label="Current Homeroom Teacher">
          <a-input
            size="large"
            class="w-100"
            :value="dataStudentClass.teacher"
            :disabled="true"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24" class="hsn-input-required">
        <a-form-item
          :label="status.isEdit ? 'New Homeroom Teacher' : 'Homeroom Teacher'"
        >
          <a-select
            size="large"
            class="w-100"
            @change="(e) => handleChange(e, 'id_guru')"
            notFoundContent="no teacher is available"
          >
            <a-select-option
              v-for="item in teacherData"
              :key="item.key"
              :value="item.id"
            >
              {{ item.nama }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Zoom Room ID">
          <a-input
            size="large"
            class="w-100"
            :value="dataStudentClass.zoom_url"
            @change="(e) => handleChange(e.target.value, 'zoom_url')"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Zoom Password">
          <a-input
            size="large"
            class="w-100"
            :value="dataStudentClass.zoom_password"
            @change="(e) => handleChange(e.target.value, 'zoom_password')"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Zoom Token">
          <a-input
            size="large"
            class="w-100"
            :value="dataStudentClass.zoom_token"
            @change="(e) => handleChange(e.target.value, 'zoom_token')"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Object,
      required: true,
    },
    dataStudentClass: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      programData: [],
      levelData: [],
      teacherData: [],
    }
  },
  methods: {
    fetchDataProgram(params = { page: 'all', order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.$store.dispatch('master/FETCH_PROGRAM', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.programData = res.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
    fetchDataLevel(params = { page: 'all', order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.programLoadingTable = true
      this.$store.dispatch('master/FETCH_LEVEL', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.levelData = res.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
    fetchDataTeacher() {
      this.$store.dispatch('master/FETCH_HOMEROOM_TEACHER_AVALAIBLE', { id: this.dataStudentClass.id_tahun_ajaran })
        .then(res => {
          console.log(res)
          this.teacherData = res.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
  mounted() {
    this.fetchDataProgram()
    this.fetchDataLevel()
    this.fetchDataTeacher()
  },
}
</script>

<style lang="scss">
// .form-student-class {
//   .hsn-input-required .ant-form-item-label label::before {
//     display: inline-block;
//     margin-right: 4px;
//     color: #f5222d;
//     font-size: 14px;
//     font-family: SimSun, sans-serif;
//     line-height: 1;
//     content: '*';
//   }
// }
</style>
